<template>
  <div class="page template-1">
    <header class="page-header container">
      <h1>{{ mrpProgramInfo.name }}</h1>
    </header>
    <!-- / Page Header -->
    <b-container class="page-body">
      <b-row class="center-elements">
        <b-col sm="6" class="search-col">
          <search-form :i18n='translations.components'/>
        </b-col>
      </b-row>
      <b-row class="table-row">
        <b-col sm="12" class="table-col mt-5">
          <data-table
            :title="tableTitle"
            :tooltip="tooltip"
            :fields="fields"
            :items="resources"
            :i18n="translations.components"
          ></data-table>
        </b-col>
      </b-row>
    </b-container>
    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import searchForm from './SearchForm.vue'
import dataTable from '@/components/DataTableMRP.vue'

import { mapActions, mapGetters } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'mrp-resources',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      tooltip: 'Testing',
      fields: [
        {
          key: 'type',
          label: 'Type',
          sortable: true,
          tdClass: 'mrpIconClass',
        },
        {
          key: 'title',
          label: 'Title',
          sortable: true,
        },
        {
          key: 'item_number',
          label: 'Item Number',
          sortable: true,
        },
        {
          key: 'updated',
          label: 'Updated',
          sortable: true,
        },
        {
          key: 'download_link',
          label: 'Download',
          sortable: false,
          tdClass: 'mrpDownloadClass',
        },
      ],
    }
  },
  components: {
    DataTable: dataTable,
    SearchForm: searchForm,
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      loadMRPProgramInfo: 'mrp/loadMRPProgramInfo',
    }),
    createTitle(code) {
      return {
        ax: 'Auxiliary',
        cm: 'Church Ministry',
        co: 'Officer',
        gc: 'GideonCard',
        ff: 'Faith Fund',
        mb: 'Membership',
        of: 'Officer',
        pw: 'Prayer & Personal Witnessing',
        sd: 'Scripture Distribution',
        st: 'Officer',
        xx: 'Program',
      }[code || 'xx']
    },
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        this.loadMRPProgramInfo({ lang: this.prefCulture, restype: 'resources', programid: this.mrpSelectedProgramId }),
      ]).then(() => {
        this.setLoadingStatus(false)
      })
    },
    async setDataTableFields() {
      const { tcDownload, tcItemNumber, tcTitle, tcType, tcUpdated } = this.translations.components['data-table-mrp']
      this.fields[0].label = tcType
      this.fields[1].label = tcTitle
      this.fields[2].label = tcItemNumber
      this.fields[3].label = tcUpdated
      this.fields[4].label = tcDownload
      return true
    },
    sortTheArray(listingArray, sortOn, makeDesc) {
      let s1 = makeDesc ? -1 : 1
      let s2 = makeDesc ? 1 : -1
      return listingArray.sort((a, b) => (a[sortOn] > b[sortOn] ? s1 : s2))
    },
  },
  computed: {
    ...mapGetters({
      mrpSelectedProgramId: 'mrp/mrpSelectedProgramId',
      mrpProgramInfo: 'mrp/mrpProgramInfo',
      prefCulture: 'user/userPreferredCulture',
    }),
    tableTitle() {
      return this.mrpProgramInfo.resourceTitle
    },
    resources() {
      let newResourceList = this.mrpProgramInfo.resourceListing.map((r) => {
        return {
          category: 'resources',
          itemId: r.ResourceId,
          item_number: r.ResourceItemNumber,
          title: r.ResourceName,
          icon: r.ResourceThumbnailId,
          type: r.ResourceType,
          downloadIcon: '479F189B-353E-48B3-817E-FC784AC59C1B', //GUID in sitecore for download icon
          download_link: r.ResourceLink,
          link_url: r.ResourceLink,
          updated: r.ResourceUpdated,
        }
      })

      return newResourceList
    },
  },
  async created () {
    //need a selected mrp program id
    if (!this.mrpSelectedProgramId) {
      this.$router.push('/resources/media-portal/')
    }
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('data-table-mrp', 'mrp-search-form'),
      this.pageLoad()
    ]).then(results => {
      const componentTranslations = results[1]
      this.$set(this.translations, 'components', componentTranslations)
      this.setDataTableFields()
    })
  }
}
</script>

<style lang="scss" scoped>
/* eslint-disable */
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
.page {
  .page-body {
    @include breakpoint(sm) {
      margin-top: 3rem;
    }
    h4 {
      margin-bottom: 10px;
    }
  }
}

.table-row {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  .table-col {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    background-color: #ffffff;
    padding: 15px 0;
    box-shadow: 0 9px 24px 6px rgba(157, 157, 157, 0.5);
    .tile-col-card {
      flex-grow: 2;
    }
  }
}
</style>
